<template>
  <div class="w-full space-y-6 mb-6">
    <div>
      <p>
        {{ $t('page.settings.about_my_business.description') }}
      </p>
      <p>{{ $t('page.settings.about_my_business.sentence.answer_the_questions') }}</p>
    </div>

    <div>
      <base-textarea
        :label="$t('form.label.about_specialization')"
        name="specialization"
        rows="2"
        autocomplete="off"
        v-model="specialization"
        :placeholder="$t('form.placeholder.about_specialization')"
        :help="$t('sentence.min_characters-dynamic', { count: 20 })"
        show-counter
        :counter-min="20"
      />
    </div>

    <div>
      <base-textarea
        :label="$t('form.label.about_audience')"
        name="audience"
        rows="2"
        autocomplete="off"
        v-model="audience"
        :placeholder="$t('form.placeholder.about_audience')"
        :help="$t('sentence.min_characters-dynamic', { count: 20 })"
        show-counter
        :counter-min="20"
      />
    </div>

    <div>
      <base-textarea
        :label="$t('form.label.about_profile_product')"
        name="profileProduct"
        rows="2"
        autocomplete="off"
        v-model="profileProduct"
        :placeholder="$t('form.placeholder.about_profile_product')"
        :help="$t('sentence.min_characters-dynamic', { count: 20 })"
        show-counter
        :counter-min="20"
      />
    </div>

    <div>
      <base-textarea
        :label="$t('form.label.about_content_purpose')"
        name="contentPurpose"
        rows="2"
        autocomplete="off"
        v-model="contentPurpose"
        :placeholder="$t('form.placeholder.about_content_purpose')"
        :help="$t('sentence.min_characters-dynamic', { count: 20 })"
        show-counter
        :counter-min="20"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import { defineComponent, ref, watch, onMounted } from 'vue'

export default defineComponent({
  components: { BaseTextarea },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:form'
  ],
  setup (props, { emit }) {
    const specialization = ref<string>('')
    const audience = ref<string>('')
    const contentPurpose = ref<string>('')
    const profileProduct = ref<string>('')

    watch(specialization, (specialization) => {
      emit('update:form', {
        specialization
      })
    })

    watch(audience, (audience) => {
      emit('update:form', {
        audience
      })
    })

    watch(contentPurpose, (contentPurpose) => {
      emit('update:form', {
        contentPurpose
      })
    })

    watch(profileProduct, (profileProduct) => {
      emit('update:form', {
        profileProduct
      })
    })

    onMounted(() => {
      specialization.value = props.form.specialization
      ? props.form.specialization
      : ''

      audience.value = props.form.audience
      ? props.form.audience
      : ''

      contentPurpose.value = props.form.contentPurpose
      ? props.form.contentPurpose
      : ''

      profileProduct.value = props.form.profileProduct
      ? props.form.profileProduct
      : ''
    })

    return {
      specialization,
      audience,
      contentPurpose,
      profileProduct
    }
  }
})
</script>
