
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import { defineComponent, ref, watch, onMounted } from 'vue'

export default defineComponent({
  components: { BaseTextarea },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:form'
  ],
  setup (props, { emit }) {
    const specialization = ref<string>('')
    const audience = ref<string>('')
    const contentPurpose = ref<string>('')
    const profileProduct = ref<string>('')

    watch(specialization, (specialization) => {
      emit('update:form', {
        specialization
      })
    })

    watch(audience, (audience) => {
      emit('update:form', {
        audience
      })
    })

    watch(contentPurpose, (contentPurpose) => {
      emit('update:form', {
        contentPurpose
      })
    })

    watch(profileProduct, (profileProduct) => {
      emit('update:form', {
        profileProduct
      })
    })

    onMounted(() => {
      specialization.value = props.form.specialization
      ? props.form.specialization
      : ''

      audience.value = props.form.audience
      ? props.form.audience
      : ''

      contentPurpose.value = props.form.contentPurpose
      ? props.form.contentPurpose
      : ''

      profileProduct.value = props.form.profileProduct
      ? props.form.profileProduct
      : ''
    })

    return {
      specialization,
      audience,
      contentPurpose,
      profileProduct
    }
  }
})
